import { useSession } from "contexts";
import { ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { OrgTypes } from "utils/orgTypes";
import IntercomChat from "components/IntercomChat/IntercomChat";
import { useQuery } from "@apollo/client";
import { GetOrganizationBillingDocument, GetOrganizationBillingInfoDocument } from "graphql/generated";
import { AbilityContext } from "contexts/AbilityContext";
import { doesContatinSubjects } from "utils/PermissionHandler";

export default function Part91Layout({ children }: { children: ReactNode }) { 
  const { user, organizationType, organizationId } = useSession();  
  const navigate = useNavigate();
  const { data: { organization } = {}, error } = useQuery(GetOrganizationBillingDocument, {
    variables: { id: organizationId },
  });
  const ability = useContext(AbilityContext);
  // Check if the organization is cancelled
  useEffect(() => {
    if (organization?.cbInfo?.cb_status === 'cancelled') {
      if(doesContatinSubjects({ action:'PART91', subjects:['Admin'], ability}) && !window.location.href.includes('/account/settings?settingsTab=Billing')){
        navigate('/account/settings?settingsTab=Billing');
      }else if(!doesContatinSubjects({ action:'PART91', subjects:['Admin'], ability})) {
        navigate('/org-picker?cancelled=true');
      }
    }
  }, [organization]);

  //Check if user is logged in
  useEffect(() => {
    if (!user) {
      navigate('/auth');
    }

    if(organizationType === OrgTypes.PART145){ //kick out any users who are under 145
      navigate('/mro/dashboard');
    }
  }, [user, navigate]);

  return (
    <>
      <Sidebar>
        <main className="flex flex-col items-center justify-center">
          <div className="w-full max-w-7xl relative px-4 md:px-8 md:p-4 2xl:border-x border-dashed border-brand-pale 2xl:h-[calc(100vh-73px)] mt-2 lg:pb-0 pb-36">
            {children}
          </div>
        </main>
      </Sidebar>
      <IntercomChat />
    </>
  )
}
